@import 'shared';

.root {
  display: block;

  &.label-1 {
    @include typography-label-1;
  }

  &.label-2 {
    @include typography-label-2;
  }

  &.label-3 {
    @include typography-label-3;
  }

  &.uppercase {
    text-transform: uppercase;
  }

  &.italic {
    font-style: italic;
  }
}
