@import 'shared';

.root {
  // @NOTE: Special typography case close to h1 and 404
  --size: #{px(88)};

  @include media-tablet {
    --size: #{px(116)};
  }

  @include media-desktop {
    --size: #{px(140)};
  }

  display: flex;
  position: relative;
  width: fit-content;
  height: var(--size);
  overflow: hidden;

  .indicator {
    height: var(--size);
    position: absolute;
    top: 0;
    width: 150%;
    left: -25%;
    outline: 1px solid $black;
  }

  .digitWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: var(--size);
  }

  .digit {
    @include font-family('romie', 400);
    min-height: var(--size);
    letter-spacing: 1 !important;
    line-height: 1 !important;
    font-size: var(--size) !important;
    vertical-align: top;
  }

  .postfix {
    @include typography-h4;
    align-self: flex-end;
    margin-left: px(4);
    padding-right: px(4);
  }
}
