@import 'shared';

.root {
  @include flex-center;
  cursor: pointer;
  border-radius: px(50);
  line-height: 1 !important;
  width: fit-content;
  white-space: nowrap;

  @include ui-transition(background-color, border-color, color);

  background-color: var(--background);
  color: var(--color);
  border-width: 1px;
  border-style: solid;
  border-color: var(--border);

  &.flexAlignment-start {
    align-self: flex-start;
  }

  &.flexAlignment-end {
    align-self: flex-end;
  }

  &.flexAlignment-center {
    align-self: center;
  }

  &.theme-dark {
    --background: #{$black};
    --color: #{$white};
    --border: transparent;
  }

  &.theme-light {
    --background: #{$white};
    --color: #{$black};
    --border: transparent;
  }

  &.theme-modal {
    --background: #bbb7b7;
    --color: #{$black};
    --border: transparent;
  }

  &.theme-border {
    --color: #{$black};
    --border: #{$black};
    --background: transparent;
  }

  &.size-small,
  &.size-close {
    @include typography-label-3($text-transform: uppercase);
    padding: 0 px(10);
    height: px(29);

    @include media-desktop {
      height: px(26);
    }
  }

  &.size-close {
    width: px(40);
  }

  &.size-medium {
    @include typography-label-2($text-transform: uppercase);
    padding: px(10) px(18);
  }
}
