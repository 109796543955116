@use 'sass:math';

@import 'fonts';
@import 'shared';

html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(#000, 0);
  -webkit-overflow-scrolling: auto;

  box-sizing: border-box;
  text-size-adjust: none;
  overscroll-behavior: none;
  background-color: $white;
  color: $black;

  // Scaling
  // It will scale the website using rems until the $design
  // width is reached. After that, it will scale using viewport units.
  // This will make sure that the website will always look good on
  // any screen size.
  $base-font-size: 10;
  $min-scale-size: $design-lock;
  $max-scale-size: 3840;
  $min-font-size: $base-font-size * 1px;
  $viewport-font-size: $base-font-size / $min-scale-size * 100vw;
  $max-font-size: $base-font-size * ($max-scale-size / $min-scale-size) * 1px;

  font-size: $min-font-size;

  @media (min-width: #{$min-scale-size}px) {
    font-size: clamp($min-font-size, $viewport-font-size, $max-font-size);
  }

  // Dynamic responsiveness.
  // It keeps everything in proportion on slightly diffeent screen sizes. We will not have to worry about overlaps happening
  // on narrow devices such as iPhone 5 (320px wide) or too much space on large devices like iPhone 8 Plus (414px wide).
  // We just need to make sure to use the px() mixing FOR EVERYTHING (except things that are required to be 1px, like borders)
  // Just copy the value you see on Figma and everything should stay proportional at all times.
  // font-size: #{math.div(math.round(math.div(100, $design-width-mobile) * 10000), 1000)}vw;
  // @include media-tablet {
  //   font-size: #{math.div(math.round(math.div(100, $design-width-tablet) * 10000), 1000)}vw;
  // }
  // @include media-desktop {
  //   font-size: #{math.div(math.round(math.div(100, $design-width-desktop) * 10000), 1000)}vw;
  // }

  // Regular responsiveness
  // font-size: 10px;
  // But scale down on really small screens
  @media (max-width: #{$breakpoint-mobile - 1}px) {
    font-size: #{math.div(math.round(math.div(100, $design-width-mobile) * 10000), 1000)}vw;
  }

  // font-size: #{math.div(math.round(math.div(100, $design-width-mobile) * 10000), 1000)}vw;

  // @include media-tablet {
  //   font-size: #{math.div(math.round(math.div(100, $design-width-tablet) * 10000), 1000)}vw;
  // }

  // @include media-desktop {
  //   font-size: #{math.div(math.round(math.div(100, $design-width-desktop) * 10000), 1000)}vw;
  // }
}

body {
  @include typography-bodycopy-1;
  margin: 0;
  padding: 0;
  word-break: keep-all;
  width: 100%;
}

main {
  min-height: 100svh;
}

*,
*::before,
*::after {
  box-sizing: inherit;

  .mobile & {
    user-select: none;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

ul,
dl,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

li,
dt,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote {
  margin: 0;
  padding: 0;
}

button {
  border: none;
  cursor: pointer;
  background-color: transparent;
  padding: 0;
  text-align: inherit;
  letter-spacing: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  text-transform: inherit;
  display: inline-block;
  margin: 0;
  text-decoration: none;
  appearance: none;
  color: currentcolor;
}

button,
input,
a {
  &:focus {
    outline-offset: 4px;
  }

  &:focus:not(.focus-visible) {
    outline: none;
  }
}

i {
  font-style: normal;
}

svg {
  display: block;
  overflow: visible;
  fill: currentcolor;
}

// Lenis CSS

html.lenis {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-scrolling iframe {
  pointer-events: none;
}

// Swiper CSS

.swiper-slide-active {
  z-index: 1;
}

// Global fix for split word

.split-word {
  padding-right: calc(1ch + 2px);
  margin-right: -1ch;
  pointer-events: none;
}

// Global fix for split char - Work page specially

.split-char {
  pointer-events: none;
}

// Custom scrollbar CSS
// @TODO: Create custom scrollbar with 2 themes
// with the help of https://scrollbar.app/

// @TODO: Also add a custom scrollbar for the project modal
