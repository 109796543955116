@import 'shared';

.root {
  @include position-100(fixed);
  pointer-events: none;
  z-index: 99999999;

  .container {
    @include position-100(absolute);
    @include page-spacing;

    .column {
      height: 100%;
      outline: 1px dotted var(--color);
      background-color: var(--background);
    }

    @include mobile-grid;

    @include media-tablet {
      @include tablet-grid;
    }

    @include media-desktop {
      @include desktop-grid;
    }
  }

  &.themeDark {
    --color: #{rgba(black, 0.4)};
  }

  &.themeLight {
    --color: #{rgba(white, 0.4)};
  }

  .diagonalContainer {
    @include position-100(absolute);
    @include page-spacing;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;

    .column {
      height: 100%;
      outline: 1px dashed var(--color);
    }
  }
}
