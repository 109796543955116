@import 'vars';

@font-face {
  font-family: neue-montreal;
  src: url('~@/assets/fonts/PPNeueMontreal-Book.woff2') format('woff2');
  font-display: block;
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: neue-montreal;
  src: url('~@/assets/fonts/PPNeueMontreal-Medium.woff2') format('woff2');
  font-display: block;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: romie;
  src: url('~@/assets/fonts/Romie-Regular.woff2') format('woff2');
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: romie;
  src: url('~@/assets/fonts/Romie-Italic.woff2') format('woff2');
  font-display: auto;
  font-style: italic;
  font-weight: 400;
}
