@import 'shared';

.root {
  position: fixed;
  top: $page-padding-desktop;
  right: $spacing;
  display: flex;
  flex-direction: row-reverse;
  gap: px(10);
  z-index: 1000;
  opacity: 0;
  pointer-events: none;

  .toggleButton,
  > a {
    pointer-events: all;
  }

  > a {
    opacity: 0;
  }

  @include media-desktop {
    gap: px(8);
    flex-direction: column;
    align-items: end;
  }

  // @NOTE: This is a copy paste from the PillButton

  .toggleButton {
    --button-padding-offset: #{px(7.7)};
    --cross-offset: #{px(8)};
    --button-width: #{px(48)};
    --button-height: #{px(29)};

    @include ui-transition(border-color, color);

    border-width: 1px;
    border-style: solid;
    border-color: var(--nav-button-border);
    color: var(--nav-button-color);
    width: var(--button-width); // @NOTE: Hardcoded width to fit the word MENU
    height: var(--button-height);
    position: relative;

    @include media-tablet {
      --button-width: #{px(50)};
      --button-padding-offset: #{px(7)};
    }

    @include media-desktop {
      --button-width: #{px(46)};
      --button-height: #{px(26)};
      --button-padding-offset: #{px(7)};
    }

    .background {
      @include box(100%);
      position: absolute;
      background-color: var(--nav-button-background);
      z-index: -1;
      top: 0;
      right: 0;
      border-radius: px(50);

      @include ui-transition(background-color);
    }

    .menu {
      @include typography-label-3($text-transform: uppercase);
      @include transform-center-vert;
      position: absolute;
      right: calc(var(--button-padding-offset));
      line-height: 1;
    }

    .svgCross {
      @include box(px(16));
      @include transform-center-vert;
      position: absolute;
      transform-origin: center center;
      right: var(--cross-offset);

      @include media-tablet {
        --cross-offset: #{px(8)};
      }

      @include media-desktop {
        --cross-offset: #{px(7)};
      }
    }
  }

  .animated {
    position: relative;
  }

  .animated,
  .clickable {
    background-color: var(--nav-button-background);
    color: var(--nav-button-color);
    border-width: 1px;
    border-style: solid;
    border-color: var(--nav-button-border);

    // @NOTE: This is active logic if we ever need
    // position: relative;

    // &::after {
    //   content: '';
    //   position: absolute;
    //   bottom: px(4);
    //   left: px(10);
    //   width: calc(100% - px(20));
    //   height: 1px;
    //   background-color: var(--nav-button-color);
    //   opacity: 0;
    //   transition: opacity 0.667s $ease-opacity;
    // }

    // &.active {
    //   &::after {
    //     opacity: 1;
    //   }
    // }

    @include ui-transition(background-color, border-color, color);
  }

  .clickable {
    order: 100;

    @include media-desktop {
      order: 0;
    }
  }
}
