@import 'shared';

.root {
  &.paragraph-1 {
    @include typography-bodycopy-1;
  }

  &.paragraph-2 {
    @include typography-bodycopy-2;
  }

  &.uppercase {
    text-transform: uppercase;
  }

  &.italic {
    font-style: italic;
  }
}
