@import 'shared';

.root {
  --back-button-transition-duration: 0.8s;

  display: flex;
  align-items: center;
  gap: px(6);
  z-index: 999;
  width: fit-content;
  position: fixed;
  top: px(20);
  left: $spacing;
  height: px(30); // NOTE Match the Menu size

  visibility: var(--back-button-visibility);
  color: var(--back-button-color);
  opacity: var(--back-button-opacity);
  transition-duration: var(--back-button-transition-duration) !important;

  // @NOTE: Remove color to avoid wweird behavior during page transition
  // @include ui-transition(color, opacity, visibility);
  @include ui-transition(opacity, visibility);

  .button {
    display: flex;
    align-items: center;
    gap: px(6);
    width: fit-content;
  }

  .currentProjectContainer {
    display: flex;
    align-items: center;
    gap: px(6);
    opacity: 0;
    overflow: hidden;
  }

  .line {
    width: 1px;
    font-size: 0.4em;
    opacity: 0.8;
    margin: 0 px(4);
  }

  svg {
    width: px(16);
    height: px(16);
  }
}
