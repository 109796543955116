@import 'shared';

.root {
  position: fixed;
  bottom: 0;
  right: 0;
  font-size: px(12);
  font-family: monospace !important;
  text-align: left;
  background: rgba($black, 0.8);
  border-radius: px(10) 0 0;
  color: $white;
  z-index: 00110011;
  border: 1px solid rgba($white, 0.2);

  .basic {
    display: flex;
    align-items: center;
    white-space: nowrap;

    &.open {
      padding: 0 0 0 px(10);

      div {
        margin: 0 auto 0 0;
      }

      .debugDate {
        padding: 0;
      }
    }

    button {
      @include flex-center;
      width: px(20);
      height: px(30);
    }

    .debugDate {
      padding: 0 0 0 px(10);

      b {
        font-weight: 900;
      }
    }
  }

  .details {
    position: relative;
    border-top: 1px solid rgba($white, 0.2);

    .content {
      .section {
        padding: 0 px(10);

        &:not(:last-child) {
          padding-bottom: px(5);
          border-bottom: 1px solid rgba($white, 0.2);
        }

        .title {
          font-size: inherit;
          padding: px(5) 0;
          cursor: pointer;
        }

        ul {
          li {
            padding: 0 0 px(5) px(10);
          }
        }
      }
    }
  }
}
