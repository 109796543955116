@import 'shared';

.root {
  @include page-spacing;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100svh;
  background-color: $black;
  color: $white;
  z-index: 1000;
  display: inline-flex;
  padding-top: px(12);
  padding-bottom: px(12);

  @include media-tablet {
    padding-top: px(8);
    padding-bottom: px(8);
  }

  .wrapper {
    position: relative;
    opacity: 0;

    .counter {
      position: absolute;
      left: px(5);
    }
  }
}
